import { jsx } from "theme-ui"
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../ui-components/layout";
import FullScreenImage from "../components/full-screen-image/full-screen-image";
import HoverCard from "../components/hover-card/hover-card";
import SEO from "../components/seo/seo";
import Prefooter from "../components/prefooter/prefooter";
import BlogPosts from "../components/blog-posts/blog-posts";

interface ProjectHubProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        githubLink: string;
      };
    };
    allContentfulProjectHub: {
      edges: {
        node: {
          id: number;
          metaTitle: string;
          metaDescription: {
            metaDescription: string;
          };
          socialMediaImage: {
            file: {
              url: string;
            };
          };
          language: [];
          title: string;
          hero: any;
          recentProjects: {
            title: string;
            cards: {
              slug: string;
              title: string;
              subtitle: string;
              image: any;
              number: boolean;
            };
          };
          prefooter: {
            title: string;
            button: {
              slug: string;
              text: string;
              color: string;
              isButton: boolean;
            };
          };
        };
      };
    };
  };
}

export const projectHubQuery = graphql`
  query ProjectHubQuery {
    allContentfulProjectHub(limit: 1) {
      edges {
        node {
          id
          metaTitle
          metaDescription {
            metaDescription
          }
          socialMediaImage {
            file {
              url
            }
          }
          language
          title
          hero {
            ...FullScreenImageSection
          }
          recentProjects {
            title
            cards {
              slug
              title
              subtitle
              image {
                ...ContentfulAsset
              }
              number
            }
          }
          prefooter {
            ...Prefooter
          }
        }
      }
    }
  }
`;

export default class ProjectHub extends React.Component<ProjectHubProps, {}> {
  public render() {
    // @ts-ignore
    const { node: {metaTitle, metaDescription, socialMediaImage, slug, language, hero, recentProjects, prefooter} } = this.props.data.allContentfulProjectHub
      .edges[0] as ProjectHubProps;
    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
        />
        <main>
          <FullScreenImage data={hero} />
          <HoverCard data={recentProjects} maxNumber={100} id="work" />
          <BlogPosts title limitedPosts />
          <Prefooter data={prefooter} />
        </main>
      </Layout>
    );
  }
}
